<template>
    <div class="container">
        <div class="container-body">
            <div :class="ClientWidth_number > 850 ?'body-box' : 'body-box-phone'">
                <el-row>
                    <el-col :span="6">
                        <div class="grid-content bg-purple title" style="height:70px;width:128px;">
                            <h3>实战演习</h3>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            <p>
                                实战演习是集“学、练、测、赛”为一体的全方位信息安全攻防演练平台，其内容涵盖网络安全、操作系统安全、数据库安全、WEB应用安全、移动终端应用安全、无线安全、工控安全等多方向安全技术，广泛用于多行业和大型企事业单位的人才培养和人才选拔，是网络安全教学、实训、竞技实操平台
                            </p>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                   <el-col :span="24">
                       <div class="grid-content bg-purple content" style="width:50vw; margin: 0 auto;">
                           <img src="../../assets/practice.png" alt="" style="width: 100%; height:100%">
                       </div>
                   </el-col>
                </el-row>
            </div>
            <div class="body-box2" v-if="ClientWidth_number > 850">
                <el-button @click="jump()" class="pc-button" size="medium" type="primary">试用平台</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                xxx: '\u3000\u3000',
                ClientWidth_number: document.documentElement.clientWidth,
            };
        },
        created() {
            this.getClientWidth();

        },
        methods: {
            getClientWidth() {
                window.onresize = () => {
                    this.ClientWidth_number = document.documentElement.clientWidth;
                };
            },
            jump(){
                this.$nextTick(()=>{
                    window.location.href = "http://lab.cersnet.com:81/home/#/home"
                })
            },
        },
    };
</script>

<style lang='less' scoped>
    @baseWidth: 1980;
    .pxfix(@w) {
        width: (@w / @baseWidth) * 100vw;
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .container-body {
        .pxfix(1980);
        min-height: 700px;
        margin: 0 auto;
        background: #eff3f8;
        // margin-top: 20px;
        display: flex;
        flex: 1;

        .title {
            font-size: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-weight: normal;
            font-family: "microsoft yahei";
            color: #555;
        }

        .content {
            margin: 0 1rem;
            color: #555;
            font-size: 0.8rem;
            line-height: 25px;
        }

        .body-box {
            .pxfix(1040);
            margin: 1.5rem auto;
            //  flex:1;
            padding: 0 3vw;
            background: #fff;
        }

        .body-box-phone {
            background: #fff;
            margin: 1.5rem auto;
        }

        .body-box:hover {
            box-shadow: 0 0 10px #888888;
        }
    }

    .footer {
        line-height: 50px;
        background: #2f3034;
        width: 100%;
        font-size: 12px;
        color: #fff;
        text-align: center;
    }

    .body-box2 > .pc-button {
        font-size: 20px;
        width: 150px !important;
        height: 60px !important;
        position: fixed;
        top: 150px;
        right: 5vw;;
    }

    .body-box2 > .pnone-button {
        /* font-size: 20px; */
        /* width: 150px !important;
        height: 60px !important; */
        position: fixed;
        z-index: 99;
        font-weight: 600;
        top: 100px;
        right: 0;
    }

</style>
